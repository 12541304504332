import React from 'react';

import SEO from '../components/seo';
import { LayoutContent } from '../components/layout/layout-content';

import styles from '../styles/pages/404.module.scss';

const NotFoundPage = () => (
  <LayoutContent className={styles.container}>
    <SEO title="Andreas Straub | 404" />
    <h1>Seite nicht gefunden</h1>
    <div style={{ minHeight: '100vh' }}>
      <p>Die angeforderte Seite existiert nicht.</p>
    </div>
  </LayoutContent>
);

export default NotFoundPage;
